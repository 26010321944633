<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M251.812 128.5C251.812 190.471 206.464 241.851 147.141 251.268V164.676H175.963H176.124L176.148 164.517L181.668 128.528L181.701 128.312H181.482H147.141V105.156C147.141 100.256 148.34 95.4424 151.44 91.8565C154.536 88.2757 159.551 85.8906 167.243 85.8906H182.941H183.129V85.7031V55.0645V54.9062L182.973 54.8796L182.941 55.0645C182.973 54.8796 182.973 54.8796 182.973 54.8796L182.972 54.8795L182.97 54.8792L182.962 54.8778L182.931 54.8726L182.809 54.8522C182.701 54.8343 182.54 54.8081 182.332 54.7748C181.915 54.7082 181.304 54.6131 180.529 54.499C178.979 54.2708 176.77 53.9666 174.135 53.6624C168.866 53.054 161.891 52.4453 155.072 52.4453C140.815 52.4453 129.007 56.7664 120.763 65.0184C112.518 73.2706 107.859 85.4323 107.859 101.071V128.312H76.4355H76.248V128.5V164.488V164.676H76.4355H107.859V251.268C48.5361 241.851 3.1875 190.471 3.1875 128.5C3.1875 59.8441 58.8441 4.1875 127.5 4.1875C196.156 4.1875 251.812 59.8441 251.812 128.5Z"
      fill="#282F66"
      stroke="#282F66"
      stroke-width="0.375"
    />
  </svg>
</template>
